<template>
  <main>
    <div v-for="tagGroup in tagGroups">
      <div>
        <select v-model="tagGroup.settings.takeType" name="select-type" id="select-type">
          <option value="all">Take all</option>
          <option selected value="random">Choose at random</option>
        </select>

        <span class="span-random-controls" v-if="tagGroup.settings.takeType === 'random'">
        <input class="take-input" v-model="tagGroup.settings.take" type="number">
        <vue-slider :min="0" :max="tagGroup.tags.length" v-model="tagGroup.settings.take"/>
      </span>
      </div>

      <div class="span-tag-input">
      <span>
        <input style="display: none" :id="tagGroup.id" type="text" autocomplete>
      </span>
        <a class="delete-button-container">
          <img v-on:click="deleteTagGroup(tagGroup.id)" class="img-remove" src="../assets/remove.png">
        </a>
      </div>


      <br>
      <br>
      <br>
    </div>

    <div id="masterList">
      <div v-if="addDots">
        <div>.</div>
        <div>.</div>
        <div>.</div>
        <div>.</div>
      </div>
      <span v-for="tag in renderMasterList">
   {{ tag + (spacesBetween ? " " : "") }}
  </span>
    </div>
    <button id="copyButton" data-clipboard-target="#masterList">
      Copy
    </button>

    <br>
    <br>

    <button v-on:click="createTagGroup">New Group</button>

    <br>
    <br>
    <div v-if="renderMasterList.length > 0">
      <div>
        Include '#'
        <input v-model="includeTags" type="checkbox" :checked="includeTags">
      </div>

      <div>
        Add dots
        <input v-model="addDots" type="checkbox" :checked="addDots">
      </div>

      <div>
        Comma separated
        <input v-model="commaSeparated" type="checkbox" :checked="commaSeparated">
      </div>

      <div>
        Spaces between
        <input v-model="spacesBetween" type="checkbox" :checked="spacesBetween">
      </div>
    </div>
  </main>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Tagify from "@yaireo/tagify";
import ExampleWords from "@/ExampleWords";
import ClipboardJS from "clipboard";
import 'vue-slider-component/theme/antd.css'
import "@yaireo/tagify/dist/tagify.css";
import 'vue-select/dist/vue-select.css';


export default {
  name: "GenerateTags",
  components: {
    VueSlider,
  },
  computed: {
    renderMasterList() {
      let masterList = []

      Object.keys(this.tagGroups).forEach(tagGroupId => {
        let tagGroup = this.tagGroups[tagGroupId]

        const shuffledTags = tagGroup.tags.sort(() => 0.5 - Math.random());
        shuffledTags.slice(0, tagGroup.settings.take).forEach(function (tag) {
          masterList.push(tag)
        })
      })

      if (this.includeTags === true) {
        masterList.forEach(function (item, index) {
          if (!item.startsWith('#')) {
            masterList[index] = '#' + item
          }
        })
      }

      if (this.commaSeparated === true) {
        masterList.forEach(function (item, index) {
          masterList[index] = item + ','
        })
      }

      return masterList
    }
  },

  watch: {
    includeTags(newIncludeTags, oldIncludeTags) {

    },

    addDots(newIncludeTags, oldIncludeTags) {

    },
  },

  data() {
    let tagGroups = {}
    let ids = [this.uuidv4(), this.uuidv4()]

    ids.forEach(id => {
      const count = this.getRandomInt(8, 15)

      tagGroups[id] = {
        id: id,
        tags: this.getExamples(count),
        settings: {
          take: count - 5,
          takeType: 'random',
        }
      }
    })

    let queryString = this.$route.query.input

    if (queryString) {
      // Decode the String
      let decodedString = atob(queryString);
      tagGroups = JSON.parse(decodedString)
    }

    return {
      tagGroups: tagGroups,
      masterList: [],
      rendered: [],
      includeTags: true,
      addDots: false,
      commaSeparated: false,
      spacesBetween: true,
    }
  },

  mounted() {
    Object.keys(this.tagGroups).forEach(tagGroupId => {
      this.renderTagGroup(tagGroupId, this.tagGroups[tagGroupId])
    })

    new ClipboardJS('#copyButton');
  },

  updated() {
    Object.keys(this.tagGroups).forEach(tagGroupId => {
      if (!this.rendered.includes(tagGroupId)) {
        this.renderTagGroup(tagGroupId, this.tagGroups[tagGroupId])
      }
    })
  },

  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    deleteTagGroup(id) {
      const tagGroup = this.tagGroups[id]

      tagGroup.tags.forEach(tag => {
        const index = this.masterList.indexOf(tag);

        if (index > -1) {
          this.masterList.splice(index, 1);
        }
      })

      tagGroup.tagify.destroy()
      delete this.tagGroups[id]
    },

    createTagGroup(value) {
      const id = this.uuidv4()
      this.tagGroups[id] = {
        id: id, tags: [], settings: {
          take: 1,
          takeType: 'all',
        }
      }
    },

    addToMasterList(tag) {
      if (this.masterList.includes(tag)) {
        return
      }

      this.masterList.push(tag)
    },

    renderTagGroup(id, tagGroup) {
      let thisVue = this;

      let tagify = new Tagify(document.getElementById(id), {
            whitelist: [],
            transformTag: tag => {
              if (tag.value.startsWith('#')) {
                return
              }

              tag.value = '#' + tag.value
            },
            dropdown: {
              enabled: 0
            },
            callbacks: {
              add(e) {
                let tagToAdd = e.detail.data.value

                if (tagToAdd.startsWith('#')) {
                  tagToAdd = tagToAdd.substring(1);
                }

                thisVue.tagGroups[id].tags.indexOf(tagToAdd) === -1 ? thisVue.tagGroups[id].tags.push(tagToAdd) : null


                thisVue.addToMasterList(e.detail.data.value)
              },

              remove(e) {
                let tagToRemove = e.detail.data.value

                const index = thisVue.masterList.indexOf(tagToRemove);

                if (index > -1) {
                  thisVue.masterList.splice(index, 1);
                }
              },
            }
          },
      );

      this.tagGroups[id].tagify = tagify

      thisVue.rendered.push(id)

      tagify.addTags(tagGroup.tags)
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },

    getExamples(count) {
      let result = []

      for (let i = 0; i < count; i++) {
        result.push(this.exampleTags()[Math.floor(Math.random() * this.exampleTags().length)]);
      }

      return result
    },

    exampleTags() {
      const shuffled = ExampleWords.cats.sort(() => 0.5 - Math.random());

      return shuffled.slice(0, 3);
    },
  },
}

</script>

<style scoped>

main {
  max-width: 95%;
  margin: 5% auto auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  main {
    max-width: 80%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  main {
    max-width: 70%;
  }

}

.delete-button-container {
  margin-left: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.span-random-controls {
  margin-left: 1%;
}

.span-tag-input {
  display: flex;
  flex-direction: row;
}

.img-remove {
  height: 30px;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.take-input {
  width: 20px;
  text-align: center;
}

</style>