
import {Options, Vue} from 'vue-class-component';
import TagEngine from '@/components/TagEngine.vue';

@Options({
  components: {
    TagEngine,
  },
})
export default class GenerateTags extends Vue {
}
