import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import GenerateTags from "@/views/GenerateTags.vue";
import About from "@/views/About.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'About',
        component: About,
    },
    {
        path: '/generate-hash-tag-lists',
        name: 'GenerateTags',
        component: GenerateTags,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
